<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel Start here -->
        <panel
            title="DETAIL NPWPD (Nomor Pengguna Wajib Pajak Daerah)"
            class="panel panel-danger"
        >
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> DETAIL NPWP (Nomor Pengguna Wajib Pajak)</h6>
            </template> -->
            <div class="pl-4 py-2 border rounded shadow-sm">
                <b-button variant="primary" class="px-4" to="/admin/npwpd" pill>
                    <i class="fa fa-arrow-circle-left pr-2"></i>
                    Kembali
                </b-button>
            </div>
            <!-- Detail npwp -->
            <div class="py-2" v-if="profil">
                <b-card>
                    <b-tabs
                        pills
                        nav-class="mb-3"
                        content-class="rounded bg-white mb-4"
                    >
                        <b-tab active>
                            <template slot="title">
                                <span class="d-sm-none">Pills 1</span>
                                <span class="d-sm-block d-none">Profil</span>
                            </template>
                            <b-table-simple bordered small responsive>
                                <b-th colspan="6">
                                    <h4>IDENTITAS WAJIB PAJAK PROVINSI</h4>
                                </b-th>
                                <b-tr>
                                    <b-td width="200px">
                                        <span v-if="profil.jenis < 41">
                                            Nama Perusahaan
                                        </span>
                                        <span v-else>
                                            Nama 
                                        </span>
                                    </b-td>
                                    <b-td
                                        class="font-weight-bold"
                                        colspan="5"
                                    >
                                        <span v-if="profil">
                                            <span v-if="profil.bentuk_badan">{{ profil.bentuk_badan.kode }}</span> {{ profil.nama }}
                                        </span>
                                    </b-td>
                                    <!-- <b-td
                                        class="font-weight-bold text-center"
                                        v-if="profil.status_registrasi_id === 5"
                                        ><b-button
                                            variant="outline-danger"
                                            size="sm"
                                            ><i class="fa fa-file-pdf"></i>
                                            Lihat NPWPD Elektronik</b-button
                                        ></b-td
                                    > -->
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Induk</b-td>
                                    <b-td
                                        colspan="5"
                                        class="font-weight-bold"
                                        >{{ profil.no_induk }}</b-td
                                    >
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Pokok</b-td>
                                    <b-td
                                        colspan="5"
                                        class="font-weight-bold"
                                        >{{ profil.no_pokok }}</b-td
                                    >
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Handphone</b-td>
                                    <b-td colspan="5">{{ profil.nomp }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Telepon</b-td>
                                    <b-td colspan="5">{{
                                        profil.notelepon
                                    }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Email</b-td>
                                    <b-td colspan="5">
                                        {{ profil.email }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="150px">Alamat</b-td>
                                    <b-td colspan="5">
                                        <div v-if="profil">
                                            {{ profil.alamat }},
                                            <div v-if="profil.wilayah_kelurahan">
                                                Kel. {{ profil.wilayah_kelurahan.nama }},
                                                Kec. {{ profil.wilayah_kelurahan.wilayah_kecamatan.nama }},
                                                {{ profil.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.nama }},
                                                {{ profil.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi.nama }},
                                            </div>
                                            {{ profil.kodepos }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr v-if="profil.bentuk_badan">
                                    <b-td width="150px">Bentuk Badan</b-td>
                                    <b-td colspan="5">
                                        <span v-if="profil.bentuk_badan">
                                            {{ profil.bentuk_badan.nama }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr v-if="profil.pimpinan !== null || profil.penanggung_jawab !== null">
                                    <b-td>File SK</b-td>
                                    <b-td colspan="2">
                                        <b-button
                                            variant="warning"
                                            class="container"
                                            @click="showPdf(profil.pimpinan.dok_sk)"
                                            ><i class="fa fa-file-alt"></i>
                                            Lihat SK Pimpinan</b-button
                                        >
                                    </b-td>
                                    <b-td colspan="2">
                                        <b-button
                                            variant="success"
                                            class="container"
                                            @click="showPdf(profil.penanggung_jawab.dok_sk)"
                                            ><i class="fa fa-file-alt"></i>
                                            Lihat SK Penanggung Jawab</b-button
                                        >
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="5" height="20px"></b-td>
                                </b-tr>
                                <b-th colspan="5">
                                    <h4>
                                        Data Registrasi Wajib Pajak
                                    </h4>
                                </b-th>
                                <b-tr>
                                    <b-td>
                                          Nomor Registrasi
                                    </b-td>
                                    <b-td colspan="4" class="font-weight-bold">
                                        {{ profil.no_registrasi}}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                          Tanggal Registrasi
                                    </b-td>
                                    <b-td colspan="5" class="font-weight-bold">
                                        {{ profil.tgl_registrasi}}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="150px">Status Registrasi</b-td>
                                    <b-td colspan="4" class="font-weight-bold">
                                        <span v-if="profil.status_registrasi">
                                            <div v-if="profil.status_registrasi.id === 4">
                                            <span class="label label-danger">{{ profil.status_registrasi.nama }}</span>
                                        </div>
                                        <div v-else-if="profil.status_registrasi.id === 5">
                                            <span class="label label-success">{{ profil.status_registrasi.nama }}</span>
                                        </div>
                                        <div v-else-if="profil.status_registrasi.id === 3">
                                            <span class="label label-pink">{{ profil.status_registrasi.nama }}</span>
                                        </div>
                                        <div v-else-if="profil.status_registrasi.id === 2">
                                            <span class="label label-info">{{ profil.status_registrasi.nama }}</span>
                                        </div>
                                        <div v-else>
                                            <span class="label label-secondary">{{ profil.status_registrasi.nama }}</span>
                                        </div>
                                        </span>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-tab>
                        <b-tab :disabled="profil.jenis > 41">
                            <template slot="title">
                                <span class="d-sm-none">Pills 2</span>
                                <span :class="{'d-sm-block': true, 'd-none': true, 'overline': profil.jenis > 41}">Pimpinan</span>
                            </template>
                            <b-table-simple
                                bordered
                                small
                                responsive
                            >
                                <b-th colspan="5">
                                    <h4>IDENTITAS PIMPINAN PERUSAHAAN</h4>
                                </b-th>
                                <b-tr>
                                    <b-td width="200px">Nama</b-td>
                                    <b-td
                                        class="font-weight-bold"
                                    >
                                        <span v-if="profil.pimpinan">
                                            {{ profil.pimpinan.nama }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>NIK</b-td>
                                    <b-td
                                        colspan="4"
                                        class="font-weight-bold"
                                        >
                                            <span v-if="profil.pimpinan">
                                                {{ profil.pimpinan.nik }}
                                            </span>
                                        </b-td
                                    >
                                </b-tr>
                                <b-tr>
                                    <b-td>Jabatan</b-td>
                                    <b-td colspan="4">
                                        <span v-if="profil.pimpinan">
                                        {{
                                            profil.pimpinan.jabatan

                                        }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr >
                                    <b-td>Nomor Handphone</b-td>
                                    <b-td colspan="4">
                                        <span v-if="profil.pimpinan">
                                            {{
                                                profil.pimpinan.nomp
                                            }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Telepon</b-td>
                                    <b-td colspan="4">
                                        <span v-if="profil.pimpinan">
                                            {{
                                                profil.pimpinan.notelepon
                                            }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Email</b-td>
                                    <b-td colspan="4">
                                        <span v-if="profil.pimpinan">
                                            {{ profil.pimpinan.email }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="150px">Alamat</b-td>
                                    <b-td colspan="5">
                                        <span v-if="profil.pimpinan">
                                            {{ profil.pimpinan.alamat }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="5" height="20px">

                                    </b-td>
                                </b-tr>
                                <b-th colspan="5">
                                    <h4>SURAT KETETAPAN</h4>
                                </b-th>
                                <b-tr>
                                    <b-td>
                                        Nomor Surat Ketetapan
                                    </b-td>
                                    <b-td class="font-weight-bold" colspan="5">
                                        <span v-if="profil.pimpinan">
                                            {{ profil.pimpinan.no_sk }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Tanggal Surat Ketetapan
                                    </b-td>
                                    <b-td class="font-weight-bold" colspan="5">
                                        <span v-if="profil.pimpinan">
                                            {{ profil.pimpinan.tgl_sk }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Status
                                    </b-td>
                                    <b-td class="font-weight-bold" colspan="5">
                                        <span v-if="profil.pimpinan">
                                            <div v-if="profil.pimpinan.aktif === 0" class="label label-danger">
                                                Non Aktif
                                            </div>
                                            <div v-else class="label label-success">
                                                Aktif
                                            </div>
                                        </span>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-tab>
                        <b-tab :disabled="profil.jenis > 41">
                            <template slot="title">
                                <span class="d-sm-none">Pills 3</span>
                                <span :class="{'d-sm-block': true, 'd-none': true, 'overline': profil.jenis > 41}"
                                    >Penanggung Jawab</span
                                >
                            </template>
                            <b-table-simple
                                bordered
                                small
                                responsive
                            >
                                <b-th colspan="5">
                                    <h4>IDENTITAS PENANGGUNG JAWAB</h4>
                                </b-th>
                                <b-tr >
                                    <b-td width="200px">Nama</b-td>
                                    <b-td
                                        class="font-weight-bold"
                                    >
                                        <span v-if="profil.penanggung_jawab">
                                            {{ profil.penanggung_jawab.nama }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>NIK</b-td>
                                    <b-td
                                        colspan="4"
                                        class="font-weight-bold"
                                        >
                                        <span v-if="profil.penanggung_jawab">
                                            {{ profil.penanggung_jawab.nik }}
                                        </span>
                                        </b-td
                                    >
                                </b-tr>
                                <b-tr>
                                    <b-td>Jabatan</b-td>
                                    <b-td colspan="4">
                                        <span v-if="profil.penanggung_jawab">
                                            {{
                                                profil.penanggung_jawab.jabatan
                                            }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Handphone</b-td>
                                    <b-td colspan="4">
                                        <span v-if="profil.penanggung_jawab">
                                            {{
                                            profil.penanggung_jawab.nomp
                                        }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Telepon</b-td>
                                    <b-td colspan="4">
                                        <span v-if="profil.penanggung_jawab">
                                            {{
                                                profil.penanggung_jawab.notelepon
                                            }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Email</b-td>
                                    <b-td colspan="4">
                                        <span v-if="profil.penanggung_jawab">
                                            {{ profil.penanggung_jawab.email }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="150px">Alamat</b-td>
                                    <b-td colspan="5">
                                        <span v-if="profil.penanggung_jawab">
                                            {{ profil.penanggung_jawab.alamat }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-th colspan="5">
                                    <h4>SURAT KETETAPAN</h4>
                                </b-th>
                                <b-tr>
                                    <b-td>
                                        Nomor Surat Ketetapan
                                    </b-td>
                                    <b-td class="font-weight-bold" colspan="5">
                                        <span v-if="profil.penanggung_jawab">
                                            {{ profil.penanggung_jawab.no_sk }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Tanggal Surat Ketetapan
                                    </b-td>
                                    <b-td class="font-weight-bold" colspan="5">
                                        <span v-if="profil.penanggung_jawab">
                                            {{ profil.penanggung_jawab.tgl_sk }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Status
                                    </b-td>
                                    <b-td class="font-weight-bold" colspan="5">
                                        <span v-if="profil.penanggung_jawab">
                                            <div v-if="profil.penanggung_jawab.aktif === 0" class="label label-danger">
                                                Non Aktif
                                            </div>
                                            <div v-else class="label label-success">
                                                Aktif
                                            </div>
                                        </span>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-tab>
                    </b-tabs>
                    <!-- end nav-tabs-pills -->
                    <!-- Tool -->
                    <b-row>
                        <b-col>
                            <b-row>
                                <b-col sm="6" class="py-2"> </b-col>
                                <b-col sm="6" class="py-2"> </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <!-- end of Tool -->
                </b-card>

                <!-- Verifikasi -->
                <div class="py-2" v-if="showVer">
                    <b-card>
                        <!-- Tombol verifikasi-->
                        <label for="text"
                            >History:</label
                        >
                        <b-form-textarea
                            id="textarea-large"
                            size="lg"
                            v-model="profil.ket_status"
                            rows="6"
                            disabled
                        ></b-form-textarea>
                        <b-form 
                            @submit.prevent="verifikasi"
                            @keydown="form.onKeydown($event)"
                            autocomplete="off">
                            <b-row>
                                <b-col sm="6" class="py-2">
                                    <label for="verifikasi"
                                        >Verifikasi Data:</label
                                    >
                                    <v-select
                                        label="nama"
                                        :reduce="(nama) => nama.id"
                                        v-model="form.status_registrasi_id"
                                        :options="statusOptions"
                                        placeholder="Pilih Status"
                                    >
                                    </v-select>
                                    <em
                                        v-if="form.errors.has('status_registrasi_id')"
                                        class="form-text text-danger"
                                        ><i class="fa fa-exclamation-triangle"></i>
                                        {{ errors.status_registrasi_id[0] }}</em
                                    >
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                     <label for="verifikasi"
                                        >Keterangan:</label
                                    >
                                    <b-form-textarea
                                        id="textarea-large"
                                        size="lg"
                                        v-model="form.ket_status"
                                        rows="6"
                                        placeholder="Masukkan Keterangan"
                                    ></b-form-textarea>
                                    <em
                                        v-if="form.errors.has('ket_status')"
                                        class="form-text text-danger"
                                        ><i class="fa fa-exclamation-triangle"></i>
                                        {{ errors.ket_status[0] }}</em
                                    >
                                </b-col>
                            </b-row>
                            <div class="py-2">
                                <span>
                                    <b-button
                                        variant="primary"
                                        type="submit"
                                        >
                                        <span v-if="isLoading"
                                            ><b-spinner
                                                variant="light"
                                                small
                                                label="loading"
                                            ></b-spinner
                                        ></span>
                                        <i class="fa fa-check-circle" v-else></i>
                                        Verifikasi</b-button
                                    >
                                </span>
                                <span class="mx-2">
                                    <b-button variant="secondary" @click="hapusKet">
                                        <i class="fa fa-redo-alt"></i> Hapus Keterangan
                                    </b-button>
                                </span>
                            </div>
                        </b-form>
                    </b-card>
                </div>
            </div>
        </panel>
        <!-- Panel end here -->
        <!-- Modal pdf -->
        <b-modal id="modal-1" size="xl" v-model="showModalData" hide-footer hide-header title="Surat Ketetapan" no-close-on-backdrop>
            <b-row>
				<b-col>
					<h5>Detail SURAT KETETAPAN</h5>
				</b-col>
				<b-col>
					<span class="float-right pb-2">
						<!-- <b-button variant="outline-dark" @click="closeModal"><i class="fa fa-times"></i></b-button> -->
						<i class="fa fa-times fa-2x tutup" @click="closeModal" v-b-tooltip.hover title="Tutup" style="cursor: pointer;"></i>
					</span>
				</b-col>
			</b-row>
            <div v-if="notFound">
                <b-alert variant="danger" show class="text-center">
                    Data Surat Ketetapan tidak dapat ditemukan.
                </b-alert>
            </div>
			<div v-else>
				<iframe :src="pdfUrl" frameborder="1" height="1100" width="100%"></iframe>
			</div>
        </b-modal>  
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from "@/assets/images/logoSumut.png";
import axios from "axios";
import HeadTitle from "@/components/header/HRetribusi.vue";

export default {
    components: {
        HeadTitle,
    },
    data() {
        return {
            imageLogo: Logo,
            // variabel profil
            statusOptions: [],
            profil: null,
            verifikasi_var: null,
            form: new Form({
                id: '',
                no_pokok: '',
                status_registrasi_id: '',
                ket_status: ""
            }),
            errors: [],
            isLoading: false,
            notFound: false,
            pdfUrl: '',
            showModalData: false,
            showVer: JSON.parse(localStorage.getItem('showVer'))
        };
    },
    mounted() {
        this.getProfilNPWPD();
        this.getStatusRegistrasi();
    },
    methods: {
        // Data Detail NPWPD
        getProfilNPWPD() {
            axios
                .get("/api/wajib-pajak/npwpd/" + this.$route.params.id)
                .then((response) => {
                    this.profil = response.data.data;
                    console.log(this.profil)
                    this.form.fill(response.data.data)
                    this.form.id = String(this.profil.id)
                    this.verifikasi_var = this.profil.status_registrasi_id
                    // console.log(this.profil)
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({name: 'Login'})
                    }
                });
        },
        getStatusRegistrasi() {
            axios
                .get("/api/wajib-pajak/status-registrasi/getoption")
                .then((response) => {
                    this.statusOptions = response.data.data;
                    // console.log(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                     if (error.response.status === 401) {
                          localStorage.removeItem("EP_token");
                          this.$router.push({name: 'Login'})
                      }
                });
        },
        // Verifikasi
        verifikasi() {
            this.isLoading = true
            this.form.put('/api/wajib-pajak/npwpd/verifikasi/' + this.$route.params.id).then(response => {
                const items = response.data.data
                if (items.status_registrasi_id < 5) {
                    if (this.form.successful) {
                        this.isLoading = false
                        this.$swal({
                            icon: "success",
                            title: "Data Berhasil Dirubah.",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                            allowOutsideClick: true
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.verifikasi_var = response.data.data.status_registrasi_id
                                this.getProfilNPWPD()
                            }
                        });
                    }
                } else {
                    if (this.form.successful) {
                        this.isLoading = false
                        this.$swal({
                            icon: "success",
                            title: "Data Berhasil Diverifikasi.",
                            text: "Data yang disimpan tidak dapat dirubah kembali.",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            allowOutsideClick: true,
                            showCloseButton: true,
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.verifikasi_var = response.data.data.status_registrasi_id
                                this.getProfilNPWPD()
                            }
                        });
                    }
                }
            }).catch(error => {
                this.isLoading = false
                if (error.response.status === 401) {
                    localStorage.removeItem("EP_token");
                    this.$router.push({name: 'Login'})
                } else if (error.response.status === 422) {
                    this.errors = error.response.data
                }
            })
        },
        showPdf (id) {
            this.$root.$emit("bv::show::modal", "modal-1")
            this.showModalData = true
            this.getPdf(id)
        },
        // close modal
        closeModal () {
            this.$root.$emit("bv::hide::modal", 'modal-1');
            this.showModalData = false
            this.pdfUrl = ''
        },
        // pdf
        getPdf (value) {
            axios.get('/api/wajib-pajak/npwpd-wakil/view-pdf/doksk-' + value, {responseType: 'arraybuffer'}).then(response => {
                this.pdfUrl = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            }).catch(error => {
                console.log(error)
                if (error.response.status === 404) {
                    this.notFound = true
                } else if (error.response.status == 401) {
                    localStorage.removeItem("EP_token");
                    this.$router.push({ name: "Login" });
                }
            })
        },
        // hapus keterangan
        hapusKet () {
            this.form.ket_status = ''
        }
    },
};
</script>

<style scoped>
.modal-sm {
    width: 200px;
}
.overline {
    text-decoration: line-through;
}
</style>
